:root {
    --white: rgb(255, 255, 255);
    --black: rgb(0, 0, 0);
    --dark-gray: #f1f1f1;
    /* --dark-gray: #D3D3D3; */
    --light-gray: #f1f1f1;
    --primary-color: #13293d;
    --secondary-color: #247ba0;
    --online-background-color: #273a4d;
    --detail-color: rgb(27, 152, 224);
    --deep-blue-font-color: rgb(4, 56, 74);
    --footer-light-blue-color: rgb(140, 194, 217);
    --blue-icon-color: rgb(85, 122, 148);
    --header-background-color: rgb(27, 100, 152);
    --service-info-color: rgba(19,41,61, .6);
    --location-icon-color: rgb(32, 74, 103);

    --domine: 'Domine', serif;
    --open-sans: 'Open Sans', sans-serif;
}

* {
    font-size: 62.5%;
}

body {
    font-family: var(--open-sans);
    font-weight: 400;
    background: var(--white);
    font-size: 1.6rem;
}

header {
    background: var(--white);
    border-bottom: 1rem solid var(--primary-color);
    position: sticky;
    top: 0;
    z-index: 20;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

nav {
    background-color: var(--primary-color);
}

a {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
}

p {
    font-size: 1.6rem;
    text-align: justify !important;
}

button:hover {
    font-weight: bold;
}

.nav-menu > li > a {
    font-size: 1.6rem;
    padding: .5rem 0rem;
}

.nav-menu > li > a:hover {
    font-weight: 600;
    color: var(--white);
}

.logo {
    max-width: 22rem;
}

.section-title {
    font-family: var(--domine);
    font-size: 2.6rem;
    width: auto;
    display: inline;
    position: relative;
}

.section-title-stroke {
    width: 160%;
    max-width: 30rem;
    height: 5px;
    background-color: var(--secondary-color);
    margin-top: 1rem;
    position: absolute;
}

.section-title-stroke-center {
    left: 50%;
    transform: translateX(-50%);
}

.section-description {
    font-size: 1.4rem;
    margin-top: 2.3rem;
}

.section-description p > a {
    font-size: 1.4rem;
}

.info-block {
    padding: .5rem 0rem;
}

.info-block > img {
    max-height: 3.2rem;
    padding-right: 1rem;
}

.info-block p {
    font-size: 1.2rem;
    font-family: var(--domine);
    margin: 0;
    padding: 0rem 0rem ;
}

.info-block h4 {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--primary-color);
}



.mobile-menu-button {
    width: 5rem;
    border: none;
    outline: none;
    background: none;
}

.mobile-menu-button > img {
    width: 100%;
}

.mobile-menu {
    width: 100%;
    color: var(--primary-color);
    font-weight: 700;
}

.mobile-menu > h3 {
    font-size: 2.2rem;
}

.mobile-menu > h4 {
    font-size: 1.4rem;
    opacity: .7;
}

.mobile-menu ul {
    margin-top: 1rem;
}

.mobile-menu ul > a > li  {
    font-size: 1.8rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--secondary-color);
}

.mobile-menu ul > a:last-child > li {
    margin-bottom: 0rem;
    border-bottom: none;
}

.main-image {
    min-height: 24rem;
    border-top: 1rem solid var(--primary-color);
}

.main-image * {
    color: var(--white);
}

.main-image > h1 {
    font-weight: 700;
    font-size: 3.2rem;
}

.main-image > h2 {
    font-size: 2.2rem;
}

.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    overflow: hidden;
    background-color: var(--header-background-color);
}

.header-background > img {
    height: 100%;
    opacity: .4;
}

.alert {
    background-color: var(--primary-color);
    color: var(--white);
    font-family: var(--domine);
    border-radius: 0px;
}

.alert > h4 {
    margin: 0;
    padding: 0;
}

.alert a {
    font-size: inherit;
    font-weight: bold;
}

.map-patos {
    width: 100%;
    max-width: 30rem;
}

.request-section {
    background-color: var(--online-background-color);
}

.request-button {
    background-color: var(--white);
    width: 100%;
    max-width: 40rem;
    display: flex;
    align-items: center;
    height: 8rem;
    border: none;
    outline: none;
    border-radius: 10px 10px 10px 0px;
    font-family: var(--domine);
    padding: 0rem;
    overflow: hidden;
}

.request-button > .hold-icon {
    background-color: var(--secondary-color);
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10px;
}

.request-button > .hold-icon > img {
    /* width: 100%; */
    max-height: 4rem;
}

.request-button > h4 {
    font-family: var(--domine);
    color: var(--primary-color);
    font-weight: bold;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    /* text-align: center;
    width: 100%; */
    padding-left: 1.5rem;
}

.service {
    background-color: var(--light-gray);
    min-height: 65rem;
}

.service-block {
    position: relative;
    color: var(--white);
    box-sizing: border-box;
}

.service-block:hover > .service-info {
    background: var(--primary-color);
}

.service-image {
    height: 18rem;
    overflow: hidden;
    display: grid;
    place-items: center;
    position: relative;
}

.service-image > img {
    position: absolute;
    height: 80%;
}

.service-info {
    width: 100%;
    background: rgba(19,41,61, .8);
    padding: 2rem;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1rem solid var(--secondary-color);
    height: 16rem;
}

.service-info > h4 {
    font-family: var(--domine);
    font-size: 2rem;
    font-weight: 700;
}

.service-info > p {
    font-size: 1.4rem;
}

.service-page-image {
    border-radius: 0px 30px 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.service-page-image > img {
    max-width: 25rem;
}



.service-page-info > h4 {
    font-family: var(--domine);
    font-size: 2.4rem;
    font-weight: 700;
    position: relative;
    margin-top: 2rem;
}

.service-page-info > span {
    width: 40%;
    height: .3rem;
    margin-bottom: 2rem;
    display: block;
    background-color: var(--secondary-color);
}

.service-page-info > p {
    font-size: 1.6rem;
    margin-top: 1rem;
}

.service-page-info a {
    font-size: 1.8rem;
    padding: 1rem;
    min-width: 20rem;
    background-color: var(--primary-color);
}

.service-page-info a:hover {
    background-color: var(--secondary-color);
}

.service-page-selector {
    margin-top: 2rem;
    padding-right: 2rem;
    border-bottom: 2px solid var(--secondary-color);
}

.service-page-selector > h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: .5rem;
}

.service-page-selector > ul > li {
    font-size: 1.8rem;
    padding: 1rem 0rem 1rem 1rem;
    border-bottom: 1px solid var(--secondary-color);
    cursor: pointer;
}

.service-page-selector > ul > li:hover, .service-page-selector > ul > li.active {
    font-weight: bold;
    color: var(--white);
    background-color: var(--primary-color);
}


.service-page-selector > ul > li:last-child {
    border-bottom: none;
}

.location-icon {
    width: 3rem;
}

.location-icon > img {
    max-height: 3rem;
}

.location-info > small {
    font-size: 1.4rem;
    font-family: var(--domine);
}

.location-info > h5 {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--location-icon-color);
}

.location-info > h6 {
    font-size: 1.4rem;
}

footer {
    background: var(--primary-color);
    color: var(--white);
    border-top: 1rem solid var(--secondary-color);
}

.footer-logo {
    max-width: 15rem;
}

.footer-list > h5 {
    font-family: var(--domine);
    color: var(--footer-light-blue-color);
    font-size: 1.6rem;
}

.footer-list > ul > li,  .footer-list > ul > li > a {
    color: var(--white);
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 400;
}

.footer-list > ul > li:hover,  .footer-list > ul > li > a:hover {
    color: var(--secondary-color);
}

.footer-info {
    font-family: var(--domine);
    opacity: .6;
}

.footer-info > h5 {
    font-size: 1.4rem;
}

.footer-info > p {
    font-size: 1.2rem;
}

.hold-about-us-image {
    width: 100%;
    max-height: 40rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.hold-about-us-image > img {
    width: 100%;
}

.faq {
    margin-bottom: 2rem;
}

.faq div {
    background-color: var(--white);
    border-radius: 0rem 2rem 2rem 0rem;
    border-left: 6px solid var(--secondary-color);
    box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
}


.faq div > h4 {
    font-family: var(--domine);
    font-size: 2rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    font-weight: bold;
}

.faq div > p, .faq div > p > a {
    font-size: 1.6rem;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.contact-label-input > label {
    font-family: var(--domine);
    font-size: 1.8rem;
    font-weight: 600;
}

.contact-label-input > input, .contact-label-input > textarea, .contact-label-input > select {
    font-size: 1.8rem;
    padding: 1rem;
    outline: none;
    border: none;
    border-left: 4px solid var(--secondary-color);
    box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
    background-color: var(--white);
}

.contact-label-input > select > option {
    font-size: 1.8rem;
}

.contact-label-input > textarea {
    min-height: 10rem;
}

.contact-label-input > button, .contact-label-input a > button {
    padding: 1.5rem;
    font-size: 1.8rem;
    outline: none;
    color: var(--white);
    background: var(--secondary-color);
    border: none;
    border-radius: 5px;
}

.contact-label-input > button:hover {
    background: var(--primary-color);
}

.dark-gray-background {
    background-color: var(--dark-gray);
    background: var(--dark-gray);
}

.description-content > h2 {
    font-size: 1.8rem;
    font-weight: bold;
}

.description-content > p {
    font-size: 1.4rem;
}

.description-content * {
    font-size: 1.4rem;
}

.description-content table {
    width: 100%;
}

.description-content thead {
    font-weight: bold;
}

.description-content td {
    border: 1px solid var(--primary-color);
    padding: 1rem;
}


.description-content tbody > tr:first-of-type {
    font-weight: bolder;
}


.description-content strong {
    font-size: 1.8rem;
}

.record-status {
    font-size: 4rem;
}

.advice {
    width: 100%;
    height: 100vh;
    max-height: 50rem;
    max-width: 60rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white);
    border: 5px solid var(--secondary-color);
    z-index: 30;
    border-radius: 20px;
    padding: 4rem;
    overflow-y: auto;
}

.advice h1, h2 {
    font-family: var(--domine);
    font-weight: bolder;
}

.advice h1 {
    font-size: 3rem;
}

.advice h2 {
    font-size: 2rem;
}

.advice button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
}

.hold-advice {
    margin-top: -3rem;
}

.neighborhood {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
}

.neighborhood-infos {
    height: 80vh;
    max-height: 60rem;
    background-color: var(--white);
}

.hold-image {
    display: none;
}

.hold-image > img {
    height: 100%;
}

.hold-infos {
    height: 100%;
    overflow-y: auto;
    position: absolute;
    right: 0;
    width: 100%;
}



.neighborhood-infos h3 {
    font-size: 2.5rem;
    font-weight: bolder;
    font-family: var(--domine);
    color: var(--primary-color);
}

.neighborhood-infos h4 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.neighborhood-infos h5 {
    font-size: 1.8rem;
    font-weight: bolder;
}

.neighborhood-infos ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.neighborhood-infos ul:last-of-type {
    border-bottom: none;
}

.neighborhood-infos ul li {
    font-size: 1.6rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    padding: .4rem;
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    margin-bottom: 1rem;
    padding: .8rem;
}

.neighborhood-infos ul li:hover, .neighborhood-infos ul li.active {
    background: var(--primary-color);
    color: var(--white);
}


.confirm-neighborhood-button {
    width: 100%;
    background: var(--primary-color);
    color: var(--white);
    font-size: 2rem;
    padding: 1.5rem 2rem;
    border: none;
    border-radius: .5rem;
    font-weight: bolder;
    opacity: .5;
    cursor: pointer;
}

.active {
    opacity: 1;
}

.table-options {
    
}


@media screen and (min-width: 768px) {
    .main-image {
        min-height: 30rem;
    }

    .request-button > h4 {
        text-align: left;
    }
}

@media screen and (min-width: 992px) {

    header {
        border-bottom: none;
        position: unset;
    }

    nav {
        position: sticky;
        top: 0;
        z-index: 30;
        border-bottom: 1rem solid var(--secondary-color);
    }

    .main-image {
        min-height: 35rem;
        border-top: none;
    }
    

    .request-button > h4 {
        font-size: 2rem
    }
    
    .logo {
        max-width: 16rem;
    }

    .limited-size-icon {
        max-width: 19rem;
    }

    .service-page-selector {
        border-bottom: none;
        border-right: 2px solid var(--secondary-color);
    }

    .service-page-image {
        width: 20rem;
    }

    .service-info {
        height: 20rem;
    }


    .hold-image {
        display: block;
        height: 100%;
        width: 40rem;
        overflow: hidden;
        position: absolute;
        left: 0;
        border-right: 5px solid var(--primary-color);
        background-image: url('./../images/neighborhood-maps.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .hold-infos {
        width: calc(100% - 40rem);
    }
}

@media screen and (min-width: 1200px) {

    .logo {
        max-width: 22rem;
    }

    .nav-menu > li > a {
        font-size: 1.8rem;
        padding: .5rem 0rem;
    }

    .main-image {
        min-height: 45rem;
    }

    .main-image > h1 {
        font-size: 3.6rem;
    }

    .main-image > h2 {
        font-size: 2.6rem;
    }

    .header-background > img {
        height: auto;
        width: 120%;
        filter: blur(5px);
    }

    .info-block {
        padding: 2rem 0;
    }

    .info-block > img {
        max-height: 4rem;
    }
    
    .info-block p {
        font-size: 1.6rem;
        font-family: var(--domine);
        margin: 0;
        padding: 0rem 0rem ;
    }
    
    .info-block h4 {
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--primary-color);
    }

    .service-info > h4 {
        font-size: 2rem;
    }
    
    .footer-list > h5 {
        text-align: left;
    }

    .footer-list > ul {
        display: flex;
        flex-wrap: wrap;
        padding-right: 5rem;
        justify-content: space-between;
    }

    .footer-list > ul > li,  .footer-list > ul > li > a {
        color: var(--white);
        font-size: 1.4rem;
        margin-right: 1.5rem;
    }

    section {
        min-height: 50rem;
    }

    .request-section {
        min-height: 40rem;
    }

    .alert h3 {
        font-size: 1.8rem;
    }

    .alert * {
        font-size: 2rem;
    }

    .section-title {
        font-size: 3.6rem;
    }
    
    .section-description {
        font-size: 1.6rem;
    }

    .section-description h2 {
        font-size: 2rem;
        font-weight: bold;
    }
    
    .section-description * {
        font-size: 1.6rem;
    }

    .section-description a {
        font-weight: bold;
    }

    .section-description p {
        font-size: 1.6rem;
    }

    .map-patos {
        max-width: 40rem;
    }

    .request-button {
        max-width: 38rem;
        height: 8rem;
    }

    .request-button > .hold-icon > img {
        /* width: 100%; */
        max-height: 4.5rem;
    }

    .request-button > h4 {
        font-size: 2rem;
    }
    

    .service-max-paragragh-size {
        max-width: 85rem;
        margin: 3rem auto;
    }

    .service-image > img {
        width: auto;
        max-width: 16rem;
    }

    .location-icon {
        width: 5rem;
    }
    
    .location-icon > img {
        max-height: 4rem;
    }
    
    .location-info > small {
        font-size: 1.8rem;
    }
    
    .location-info > h5 {
        font-size: 2rem;
    }
    
    .location-info > h6 {
        font-size: 1.4rem;
    }

    .footer-logo {
        max-width: 20rem;
    }

    .footer-list > h5 {
        font-size: 1.8rem
    }
    

    .footer-list > ul > li,  .footer-list > ul > li > a {
        font-size: 1.8rem;
        margin-right: 1rem;
    }

    .footer-info > h5 {
        font-size: 1.8rem;
    }

    .footer-info > p {
        font-size: 1.4rem;
    }

    .description-content > h2 {
        font-size: 2rem;
    }

    .description-content > p {
        font-size: 1.6rem;
    }

}

